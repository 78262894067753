import { useEffect } from 'react';
import { navigate } from '@gatsbyjs/reach-router';

const defaultPages = {
  'donate.thehumaneleague.org': '/donate',
  'act.thehumaneleague.org': 'https://thehumaneleague.org/take-action',
};

const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    console.log('host', window.location.host);
  }
  useEffect(() => {
    const defaultPage = defaultPages[window.location.host];
    console.log('default Page', defaultPage);
    if (!defaultPage) return;

    // handle between site redirects
    if (defaultPage.startsWith('https')) {
      window.location = `${defaultPage}${window.location.search}`;
      // handle within site redirects
    } else {
      navigate(`${defaultPage}${window.location.search}`);
    }
  }, []);
  return null;
};

export default NotFoundPage;
